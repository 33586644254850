import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import { RecoilRoot } from "recoil";
// import { QueryClient, QueryClientProvider } from "react-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import router from "./route/routes";
// import * as Sentry from "@sentry/react";
import "./assets/font/pretendard.css";
import ThemeProvider from "./context/themeProvider";
import AuthProvider from "./context/Auth";
import { GlobalStyles, createTheme, ThemeProvider as ThemeProviderMui } from "@mui/material";
import GlobalStateProvider from "./context/GlobalStateProvider";
import { setupAxios } from "./service/AuthHelpers";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ErrorBoundary from "./containers/error/ErrorBoundary";

const queryClient = new QueryClient();
setupAxios(axios);

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

// // Make sure to call `loadStripe` outside of a component’s render to avoid
// // recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_51OWUFsIJz5b74ZjCoh1iTgvg9zY6zlIjnKujlStIZbakXXZLx711DHx6cr6BlJla369weGCe8vZOEPqRWJn6eqVv00ygw8Plcn");

// const options = {
//   // passing the client secret obtained from the server
//   clientSecret: 'sk_test_51OWUFsIJz5b74ZjCGL563hzNaX6boPH9teiT1tcqdTO1IP8rJheNCwJao82MWkhSnQcguyXrxtEt4qcLQVSiDBkf00EWUjVbaZ',
// }

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_APP_KEY,
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
// const globalStyles = <GlobalStyles styles={{fontFamily: "Pretendard" }} />

const theme = createTheme({
  typography: {
    fontFamily: "Pretendard, Pretendard-Regular",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 512,
      md: 768,
      lg: 1024,
      xl: 1536,
    },
  },
});
root.render(
  <ErrorBoundary fallback="There was an error">
    <QueryClientProvider client={queryClient}>
      <ThemeProviderMui theme={theme}>
        <GlobalStateProvider>
          <HelmetProvider>
            {/* <Elements stripe={stripePromise} options={options}> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider>
                <AuthProvider>
                  <RouterProvider router={router} />
                </AuthProvider>
                <RecoilRoot>
                  <App />
                </RecoilRoot>
              </ThemeProvider>
            </LocalizationProvider>
            {/* </Elements> */}
          </HelmetProvider>
        </GlobalStateProvider>
      </ThemeProviderMui>
    </QueryClientProvider>
  </ErrorBoundary>
);
