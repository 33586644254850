import React, { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { v4 as uuid } from "uuid";
import { getAnalytics, setUserId } from "firebase/analytics";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useAuth } from "../../context/Auth";
import { loginSocial } from "../../api";
import NaverLoginComponent from "./NaverLoginComponent";
import kakaoImage from "../../assets/images/kakao.png";
import appleImage from "../../assets/images/apple.png";
import googleImage from "../../assets/images/google.png";
import amazonImage from "../../assets/images/amazon.png";
import useModalReducer, { MODAL_STATUS } from "../../hooks/useModalReducer";
import { COLORS, LINE_HEIGHT } from "../../theme/constants";
import ModalComponent from "../../components/modal/ModalComponent";
import { textMediumMuiStyle } from "../../utils/commonStyle";
import useFirebaseInit from "../../hooks/useFirebaseInit";

const { Kakao, naver, AppleID } = window;

function LoginWithSocial() {
  const navigate = useNavigate();
  const { login, user: userData } = useAuth();
  const location = useLocation();
  const { firebaseAuth } = useFirebaseInit();
  const searchParams = new URLSearchParams(location.search);
  const REDIRECT_URI = `${process.env.REACT_APP_URL}/login`;
  const fcm_token = window.localStorage.getItem("push_token");
  const { modalData, dispatchModal } = useModalReducer();

  const handleShowModalErrors = (errorData) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        btnOKText: "확인",
        btnCancelText: errorData?.alert_type === 2 && "취소",
        title: errorData?.title,
        subTitle: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {errorData?.description}
          </Typography>
        ),
      },
    });
  };

  const handleLogin = async (data) => {
    try {
      const requestData = {
        ...data,
        device_id: uuid(),
        device_model: window.navigator.platform,
        device_os: "web",
      };
      const res = await loginSocial(requestData);
      if (res?.data?.data) {
        if (searchParams.get("redirect_url")) {
          window.open(
            `${searchParams.get("redirect_url")}?token=${
              res?.data?.data?.meta?.access_token?.token
            }`,
            "_self"
          );
        } else {
          localStorage.removeItem("isAccountLogin");
          login(res.data.data);
          const analytics = getAnalytics();
          setUserId(analytics, res.data.data.user.id);
        }
      }
    } catch (error) {
      switch (error?.response?.data?.code) {
        case 400:
          // handleResponseErrors({ other: [error?.response?.data?.message] });
          break;
        case 403:
          handleShowModalErrors(error?.response?.data?.data);
          break;
        default:
          // handleResponseErrors(error?.response?.data?.errors);
          break;
      }
    }
  };

  // Apple
  const handleAppleLogin = () => {
    const provider = new OAuthProvider("apple.com");
    signInWithPopup(firebaseAuth, provider)
      .then((data) => {
        const requestBody = {
          provider_type: "apple",
          provider_token: data?._tokenResponse?.oauthIdToken,
          device_token: fcm_token,
        };
        handleLogin(requestBody);
      })
      .catch((err) => {
        console.log("apple err : ", err);
        navigate("/login");
      });
  };

  // Google
  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider(); // provider 구글 설정
    provider.setCustomParameters({ prompt: "select_account" });
    signInWithPopup(firebaseAuth, provider) // 팝업창 띄워서 로그인
      .then((data) => {
        const requestBody = {
          provider_type: "google",
          provider_token: data?._tokenResponse?.oauthAccessToken,
          // device_id: deviceID,
          device_token: fcm_token,
        };
        // console.log("handleGoogleLogin requestBody", requestBody);
        handleLogin(requestBody);
      })
      .catch((err) => {
        console.log("google err : ", err);
        navigate("/login");
      });
  };

  // Naver
  const handleNaverLogin = (accessToken) => {
    try {
      const requestBody = {
        provider_type: "naver",
        provider_token: accessToken,
        device_token: fcm_token,
      };
      handleLogin(requestBody);
    } catch (error) {
      console.log("naver err : ", err);
      navigate("/login");
    }
  };

  //Amazon
  const handleAmazonLogin = () => {
    amazon.Login.setClientId(process.env.REACT_APP_AMAZON_CLIENT_ID);
    let options = {};
    options.scope = "profile";
    options.response_type = "code";
    options.pkce = true;
    amazon.Login.authorize(options, function (response) {
      if (response.error) return;

      amazon.Login.retrieveToken(response.code, function (response) {
        if (response.error) return;

        const requestBody = {
          provider_type: "amazon",
          provider_token: response.access_token,
          device_token: fcm_token,
        };
        handleLogin(requestBody);
      });
    });
  };

  const handleKakaoLoginPopup = () => {
    window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_KAKAO_APP_KEY}&redirect_uri=${REDIRECT_URI}`;
  };

  const getKakaoUserData = () => {
    const code = searchParams.get("code");
    // 카카오 인증 토큰 가져오기
    axios
      .create()
      .post(
        `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_APP_KEY}&redirect_uri=${REDIRECT_URI}&code=${code}`,
        {},
        {
          headers: {
            "Content-type": "application/x-www-form-urlenbcoded;charset=utf-8",
          },
        }
      )
      .then((res) => {
        const { data } = res;
        const { access_token } = data;

        if (access_token) {
          console.log("Bearer ", access_token);

          // 카카오 유저 정보 가져오기
          axios
            .create()
            .post(
              "https://kapi.kakao.com/v2/user/me",
              {},
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                  "Content-type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((res) => {
              console.log("데이터 통신 성공", res.data);
              const requestBody = {
                provider_type: "kakao",
                provider_token: access_token,
                // device_id: deviceID,
                device_token: fcm_token,
              };
              handleLogin(requestBody);
            });
        } else {
          console.log("access_token 없음");
        }
      })
      .catch((error) => {
        console.log(error);
        navigate("/login");
      });
  };

  useEffect(() => {
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
    }
  }, []);

  useEffect(() => {
    // const userData = JSON.parse(window.localStorage.getItem("user"));
    if (userData) {
      if (userData && searchParams.get("redirect_url")) {
        window.open(
          `${searchParams.get("redirect_url")}?token=${localStorage.getItem("access_token")}`,
          "_self"
        );
      } else {
        if (userData?.is_signup) {
          navigate("/signup/profile-settings");
        } else {
          console.log("VÀo day");
          navigate("/");
        }
      }
    } else {
      if (location.search.includes("code")) {
        if (location.search.includes("code") && !location.search.includes("scope")) {
          getKakaoUserData();
        }
      }

      if (window.location.href.includes("access_token")) {
        console.log("We got AccessToken");
        const access_token = location.hash.split("access_token=")[1].split("&state")[0];
        handleNaverLogin(access_token);
      }
    }
  }, [location, handleLogin]);

  return (
    <>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 2,
        }}
      >
        <Stack direction='row' spacing={2}>
          <Avatar
            sx={{ cursor: "pointer" }}
            alt='kakao'
            src={kakaoImage}
            onClick={handleKakaoLoginPopup}
          />
          <NaverLoginComponent
            clientId={process.env.REACT_APP_NAVER_CLIENT_ID}
            callbackUrl={REDIRECT_URI}
            render={(props) => <div onClick={props.onClick}>Naver Login</div>}
            onSuccess={(naverUser) => console.log("onSuccess naverUser", naverUser)}
            onFailure={() => console.error(result)}
          />
          <Avatar
            sx={{ cursor: "pointer" }}
            alt='apple'
            src={appleImage}
            onClick={handleAppleLogin}
          />
          <Avatar
            sx={{ cursor: "pointer" }}
            alt='google'
            src={googleImage}
            onClick={handleGoogleLogin}
          />
          <Avatar
            sx={{ cursor: "pointer" }}
            alt='amazon'
            src={amazonImage}
            onClick={() => handleAmazonLogin()}
          />
        </Stack>
      </Box>
    </>
  );
}

export default LoginWithSocial;
