import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function Event() {
  const params = useParams();

  useEffect(() => {
    if(params.id) {
      window.location.href = 'https://aladdin-ticket.picaverse.io/';
      return;
    }
  }, [])

  return (
    <>
    </>
  );
}

export default React.memo(Event);
